@layer base {
  :root {
    --color-primary: 17, 24, 39; /* #A71B4A*/
    --color-secondary: 74, 76, 90; /* #4a4c5a*/

    --color-bg: 243, 244, 245; /* #f3f4f5*/
    --color-bg-side: 252, 251, 255; /* #fcfdff*/
    --color-bg-black: 0, 0, 0;
    --digital-category-bgc: #ade8f4;
    --fashion-category-bgc: #ffddd2;
    --beauty-category-bgc: #ddd92a;
    --sport-category-bgc: #ffd60a;
    --house-category-bgc: #ccd5ae;
    --toy-category-bgc: #cce3de;
    --stationery-category-bgc: #fbb13c;

    --color-text-base: 66, 71, 80; /* #424750*/
    --color-text-muted: 107, 114, 128; /*#6b7280*/
    --color-text-side: 255, 231, 231; /*#fff*/
    --font-family: iranyekan, "IRANSans", "Tahoma";
  }

  .dark {
    --color-bg: 15, 23, 42; /* #0f172a*/
    --color-bg-side: 30, 41, 59; /* #1e293b*/
    --digital-category-bgc: #0d4c76;
    --fashion-category-bgc: #892a19;
    --beauty-category-bgc: #6d305f;
    --sport-category-bgc: #650606;
    --house-category-bgc: #164c4b;
    --toy-category-bgc: #6d3d4b;
    --stationery-category-bgc: #6a4202;

    --color-text-base: 226, 232, 240; /*#e2e8f0*/
    --color-text-muted: 140, 154, 175; /*#8c9aaf*/
    --color-text-side: 255, 231, 231; /*#fff*/
  }

  html[dir="ltr"] {
    --font-family: "Poppins", "Roboto", "sans-serif";
  }

  .min-h-without-header {
    min-height: calc(100vh - 220px);
    //手机版本
    @media (max-width: 767px) {
      min-height: calc(100vh - 100px);
    }
  }

  body {
    overflow-x: hidden; /* 防止水平滚动 */
  }

  .aspect-ratio::before {
    float: left;
    padding-top: 41.67%; /* 100% / 2.4 */
    content: "";
  }
  .aspect-ratio::after {
    display: block;
    content: "";
    clear: both;
  }

  .global-normal-text {
    font-size: large;
    @media (max-width: 767px) {
      font-size: small;
    }
  }
}
